<template>
    <div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label for="name">apartment name</label>
                <input type="text" class="form-input" id="name"  v-model="apartment.name"/>
                <span v-if="hasError('apartment.name')" class="form-error">{{ showError('apartment.name') }}</span>
            </div>
            <div class="form-col">
                <label for="phoneNumber">phone number</label>
                <the-mask
                    :data-test="apartment.phoneNumber"
                    id="phoneNumber"
                    type="text"
                    class="form-input"
                    mask="###-###-####"
                    v-model="apartment.phoneNumber"
                />
                <span v-if="hasError('apartment.phoneNumber')" class="form-error">{{ showError('apartment.phoneNumber') }}</span>
            </div>
            <div class="form-col">
                <label for="email">email</label>
                <input type="text" class="form-input" id="email"  v-model="apartment.email"/>
                <span v-if="hasError('apartment.email')" class="form-error">{{ showError('apartment.email') }}</span>
            </div>
        </div>

        <div class="form-row form-row_grid">
            <div class="form-col">
                <label>apply link</label>
                <url-input v-model="apartment.applyLink" :website-id="website.id"></url-input>
                <div class="form-hint">Leave field blank if not needed</div>
            </div>
            <div class="form-col">
                <label>tour schedule link</label>
                <url-input v-model="apartment.tourLink" :website-id="website.id"></url-input>
                <div class="form-hint">Leave field blank if not needed</div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-col">
                <label>show menu button</label>
                <toggle-input v-model="apartment.showMenuButton" />
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Widget from "@/mixins/Widget";
    import ToggleInput from "@/components/ui/ToggleInput";
    import UrlInput from "@/components/cms/UrlInput";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import {TheMask} from 'vue-the-mask';

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter, ToggleInput, UrlInput, TheMask },
        emits: ['save-page-widget'],
        data: () => {
            return {
                apartment:  {
                    name: null,
                    tagLine: null,
                    phoneNumber: null,
                    email: null,
                    applyLink: null,
                    tourLink: null,
                    showMenuButton: false,
                },
                website: {},
                property: {},
                isProcessing: false
            }
        },
        computed: {
            ...mapGetters({
                'getPropertyById': 'properties/getPropertyById',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    this.$emit('save-page-widget', Object.assign({}, this.apartment));
                }
            }, 
        },
        validations: {
            'apartment.name' : 'required',
            'apartment.email' : 'required | email',
            'apartment.phoneNumber' : 'required',
        },
        async created() {
            this.website = this.getWebsiteInfo
            this.initValidator();
            this.property = this.getPropertyById(this.website.communities_id);

            this.apartment.phoneNumber = this.website.contactInformation.contact_phone;
            this.apartment.email = this.website.contactInformation.contact_email;

            if(this.values.length) {
                Object.assign(this.apartment, {
                    name: this.getValue('name'),
                    tagLine: this.getValue('tagLine'),
                    phoneNumber: this.getValue('phoneNumber'),
                    email: this.getValue('email'),
                    tourLink: this.getValue('tourLink'),
                    applyLink: this.getValue('applyLink'),
                    showMenuButton: this.getValue('showMenuButton'),
                });
            }
        }
    }
</script>
