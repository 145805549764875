<template>
    <div>
        <div class="radio-group mt-2">
            <div class="radio">
                <input type="radio" :id="pageInputId" :name="groupName" value="page" v-model="type">
                <label :for="pageInputId">page</label>
            </div>
            <div class="radio">
                <input type="radio" :id="urlInputId" :name="groupName" value="url" v-model="type">
                <label :for="urlInputId">url</label>
            </div>
        </div>
        <dropdown
            :options="availablePages"
            placeholder="Select an existing page"
            v-model="url.page"
            v-if="isPage"
            class="mt-3"
        ></dropdown>
        <text-input
          v-if="isUrl"
          v-model="url.link"
          class="mt-3"
        />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {v4} from "uuid";
import Auth from "@/mixins/Auth";
import {resolveUrl} from "@/utils/String";
import {_v} from "@primitivesocial/clockwork";
import Dropdown from "@/components/ui/Dropdown";
import TextInput from '@/components/ui/TextInput';

export default {
    components: { Dropdown, TextInput },
    mixins: [ Auth],
    props: {
        value: {
            required: false,
            default: false,
        },
        websiteId: {
            required: true,
        }
    },
    emits: ['input'],
    data: () => {
        return {
            type: "page",
            availablePages: [],
            url: {
                link: null,
                page: null
            },
            identifier: v4(),
            pageSlug: null
        }
    },
    computed: {
        ...mapGetters({
            getPagesByWebsite: 'pages/getPagesByWebsite',
            getPageBySlugAndWebsiteId: 'pages/getPageBySlugAndWebsiteId',
            getMainPageByWebsite: 'pages/getMainPageByWebsite'
        }),
        groupName() {
            return 'url-radio-' + this.identifier;
        },
        pageInputId() {
            return 'page-' + this.identifier;
        },
        urlInputId() {
            return 'url-' + this.identifier;
        },
        isUrl() {
            return this.type === 'url';
        },
        isPage() {
            return this.type === 'page';
        }
    },
    watch: {
        url: {
            handler() {
                let page_Info = (this.type === 'page') ? this.getPageBySlugAndWebsiteId(this.url.page, this.websiteId) : undefined;                  
                let value = '/';
                if(page_Info && !page_Info.is_home_page) value = `/${this.url.page}`;
                if (!page_Info) value = resolveUrl(this.url.link);
                this.$emit("input", value);                  
            },
            deep: true,
        },
    },
    created() {
        this.getPagesByWebsite(this.websiteId).forEach( page => {
            this.availablePages.push({
                key: page.url_alias,
                value: page.website_pages_name,
            });
        })

        if(this.value && _v.url(this.value)) {
            this.type = 'url';
            this.url.link = this.value;
        }
        else if(this.value && !_v.url(this.value)) {
            this.type = 'page';
            if (this.value == '/'){
                let word = this.getMainPageByWebsite(this.websiteId)[0].url_alias
                this.url.page = word
            }else{
               this.url.page = this.value.substring(1); 
            }
            
        }
    },
}
</script>

<style scoped>

</style>

